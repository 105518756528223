import { useQuery } from "@tanstack/react-query";
import { Suspense, lazy } from "react";

const LazyLottieComponent = lazy(() => import("lottie-react"));

interface LottieAnimationData {
  v: string;
  fr: number;
  ip: number;
  op: number;
  w: number;
  h: number;
  layers: any[];
}

interface LottieProps {
  getAnimationData: () => Promise<LottieAnimationData>;
  id: string;
  loop?: boolean;
  autoplay?: boolean;
}

export const LazyLottie: React.FC<LottieProps> = ({
  getAnimationData,
  ...props
}) => {
  const { data } = useQuery({
    queryKey: [props.id],
    queryFn: async () => {
      const animationData = await getAnimationData();
      return { ...animationData };
    },
    enabled: typeof window !== "undefined",
  });

  if (!data) return <SkeletonItem />;

  return (
    <Suspense fallback={<SkeletonItem />}>
      <LazyLottieComponent animationData={data} {...props} />
    </Suspense>
  );
};

function SkeletonItem() {
  return <div>Loading...</div>;
}
